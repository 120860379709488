import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { SHOW_NOTIFICATION } from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import { formatProducts } from "./settingsActionsUtils";
import {
  ADD_NEW_PRODUCTS,
  DELETE_PRODUCT,
  PRODUCTS_FORM_TOOGLE_LOADING,
  SET_PRODUCTS,
  UPDATE_PRODUCTS,
} from "../plugins/SAT/constants";
moment().locale("es");

/* PRODUCTS */
export const fetchProducts = () => {
  return (dispatch) => {
    axios
      .get(API.products.list)
      .then((response) => {
        const products = formatProducts(response.data);
        dispatch({ type: SET_PRODUCTS, payload: keyBy(products, "_id") });
      })
      .catch((err) => err);
  };
};

export const createProducts = (newData) => {
  return async (dispatch) => {
    dispatch({ type: PRODUCTS_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.products.create, newData)
      .then((response) => {
        const product = formatProducts(response.data);
        dispatch({ type: ADD_NEW_PRODUCTS, payload: product });
        dispatch({ type: PRODUCTS_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: PRODUCTS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateProducts = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.products.edit}${updated?._id}`, updated)
      .then((response) => {
        const product = formatProducts(response.data);
        dispatch({ type: UPDATE_PRODUCTS, payload: product });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Producto actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteProducts = (Id) => {
  return (dispatch) => {
    axios
      .delete(`${API.products.delete}${Id}`)
      .then((response) => {
        dispatch({ type: DELETE_PRODUCT, payload: Id });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Producto eliminado.",
          },
        });
      })
      .catch((err) => err);
  };
};
