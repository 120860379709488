// https://testing-core.app.monhotels.com/

export const API = {
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  upload: `/upload`,
  update: `/update`,
  settings: {
    columnSettings: "/columnSettings",
  },
  establishment: {
    info: "/establishment",
    settings: "/establishment/settings",
    holidays: "/holiday/",
  },
  route: {
    create: `/route`,
    list: `/route/`,
    updateMany: `/route/`,
    delete: `/route/`,
  },
  changeDateTask: {
    create: `/changeDateTask`,
    edit: `/changeDateTask/`,
  },
  tasks: {
    create: `/task`,
    edit: `/task/`,
    status: `/task/changeStatus/`,
    delete: `/task/`,
    deleteDate: `/task/deleteFromDate`,
    archiveDate: `/task/archiveFromDate`,
    list: `/task`,
    deletedTasks: `/task/deleted`,
    archivedTasks: `/task/AllArchived`,
    total: `/task/All`,
    defaultTaskFormOptions: `/taskdefaults`,
    answer: `/answer`,
    question: `/question/`,
    audio: `/task/audio/`,
    document: `/task/document/`,
    recover: `/task/recover/`,
  },
  checklist: {
    create: `/checklist`,
    edit: `/checklist/`,
    delete: `/checklist/`,
    list: `/checklist`,
    reports: `/reports`,
    answer: `/answer/`,
    question: `/question/`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/signup/`,
    edit: `/users/`,
    changePassword: `/users/`,
    delete: `/users/`,
    get: `/user/`,
    list: `/users`,
    picture: `/user/picture`,
    statistics: `/user/statistics`,
  },
  categories: {
    create: `/category`,
    edit: `/category/`,
    delete: `/category/`,
    list: `/category`,
  },
  clients: {
    create: `/client`,
    edit: `/client/`,
    delete: `/client/`,
    list: `/client`,
  },
  providers: {
    create: `/provider`,
    edit: `/provider/`,
    delete: `/provider/`,
    list: `/provider`,
  },
  services: {
    create: `/service`,
    edit: `/service/`,
    delete: `/service/`,
    get: ``,
    list: `/service`,
    toogleUserFromService: `/user/service`,
  },
  projects: {
    create: `/project`,
    edit: `/project/`,
    delete: `/project/`,
    list: `/project`,
  },
  departments: {
    create: `/department`,
    edit: `/department/`,
    delete: `/department/`,
    list: `/department`,
  },
  chats: {
    create: `/chat`,
    list: `/chat/`,
    messages: `/message/`,
    viewedMessages: `/message/`,
  },
  resources: {
    create: `/resource`,
    edit: `/resource/`,
    delete: `/resource/`,
    list: `/resource`,
  },
  units: {
    create: `/unit`,
    edit: `/unit/`,
    delete: `/unit/`,
    list: `/unit`,
  },
  products: {
    create: `/product`,
    edit: `/product/`,
    delete: `/product/`,
    list: `/product`,
  },
  workOrders: {
    create: `/workOrder`,
    edit: `/workOrder/`,
    sign: `/workOrder/sign/`,
    delete: `/workOrder/`,
    list: `/workOrder`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  notifications: {
    create: `/notification`,
    edit: `/notification/`,
    delete: `/notification/`,
    list: `/notification`,
  },
  alertsOrders: {
    create: `/alertsOrder`,
    edit: `/alertsOrder/`,
    sign: `/alertsOrder/sign/`,
    delete: `/alertsOrder/`,
    list: `/alertsOrder`,
  },
  hours: {
    create: `/hours`,
    edit: `/hours/`,
    delete: `/hours/`,
    list: `/hours`,
  },
  files: {
    delete: `/file/`,
  },
  chatBot: {
    question: `/chatBot/`,
  },
};
