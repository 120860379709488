import { axios } from "../connection/ConnectionHandler";
import {
  SET_ESTABLISHMENT_INFO,
  SET_ESTABLISHMENT_SETTINGS,
  UPDATE_ESTABLISHMENT_INFO,
  UPDATE_ESTABLISHMENT_SETTINGS,
  SHOW_NOTIFICATION,
  SET_ALL_ESTABLISHMENT_HOLIDAYS,
  UPDATE_ESTABLISHMENT_HOLIDAY,
  DELETE_ESTABLISHMENT_HOLIDAY,
} from "../constants";
import { API } from "../constants/api";

// INFO
export const fetchEstablishmentInfo = () => {
  return (dispatch) => {
    axios
      .get(API.establishment.info)
      .then((response) =>
        dispatch({ type: SET_ESTABLISHMENT_INFO, payload: response.data })
      )
      .catch((err) => err);
  };
};

export const updateEstablishmentInfo = (info) => {
  return async (dispatch) => {
    const response = await axios
      .put(API.establishment.info, info)
      .then((response) => {
        dispatch({ type: UPDATE_ESTABLISHMENT_INFO, payload: response.data });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Datos actualizados.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

// SETTINGS
export const fetchEstablishmentSettings = () => {
  return (dispatch) => {
    axios
      .get(API.establishment.settings)
      .then((response) =>
        dispatch({ type: SET_ESTABLISHMENT_SETTINGS, payload: response.data })
      )
      .catch((err) => err);
  };
};

export const updateEstablishmentSettings = (settings) => {
  return async (dispatch) => {
    const response = await axios
      .put(API.establishment.settings, settings)
      .then((response) => {
        dispatch({
          type: UPDATE_ESTABLISHMENT_SETTINGS,
          payload: response.data,
        });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Datos actualizados.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

// HOLIDAYS
export const fetchEstbalishmentHolidays = () => {
  return (dispatch) => {
    axios
      .get(API.establishment.holidays)
      .then((response) =>
        dispatch({
          type: SET_ALL_ESTABLISHMENT_HOLIDAYS,
          payload: response.data,
        })
      )
      .catch((err) => err);
  };
};

export const createEstbalishmentHoliday = (holiday) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.establishment.holidays, holiday)
      .then((response) => {
        dispatch({
          type: SET_ALL_ESTABLISHMENT_HOLIDAYS,
          payload: response.data,
        })
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Vacaciones añadidas.",
          },
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const updateEstbalishmentHoliday = (holiday) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.establishment.holidays}${holiday?._id}`, holiday)
      .then((response) => {
        dispatch({
          type: UPDATE_ESTABLISHMENT_HOLIDAY,
          payload: response.data,
        });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Vacaciones actualizadas.",
          },
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const deleteEstbalishmentHoliday = (holidayId) => {
  return async (dispatch) => {
    const response = await axios
      .delete(`${API.establishment.holidays}${holidayId}`)
      .then((response) => {
        dispatch({ type: DELETE_ESTABLISHMENT_HOLIDAY, payload: holidayId });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Vacaciones eliminadas.",
          },
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};
