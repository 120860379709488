import { Divider } from "antd";
import { Plugins } from "@capacitor/core";
import { Fragment, useEffect, useState } from "react";
import { groupBy, isPlainObject, mapValues, toPairs } from "lodash";
import { LoremIpsum } from "lorem-ipsum";
import JSONPretty from "react-json-pretty";
import JSONTheme from "react-json-pretty/dist/monikai";
import moment from "moment";
import "moment/locale/es";

moment().locale("es");

const { Storage } = Plugins;

// OBJECTS
export const nest = function (seq, keys) {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return nest(value, rest);
  });
};

// MATH
export const percentOf = (num1, num2) =>
  Math.round((num1 / num2) * 100 * 10) / 10;

export const taskPercent = (start, end) => {
  var now = moment();
  start = moment(start, "YYYY-MM-DD") || moment(now).startOf("day");
  end = moment(end) || moment(now).endOf("day");
  var totalMillisInRange = end.valueOf() - start.valueOf();
  var elapsedMillis = now.valueOf() - start.valueOf();
  return Math.round(Math.min(100, 100 * (elapsedMillis / totalMillisInRange)));
};

// DATES
export const dateFormatter = (date) => {
  return moment(date).format("L");
};

export const randomDate = (start, end) => {
  var d = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  ),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join("/");
};

export const dateObjFormatter = (date, separator = "/") => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join(separator);
};

export const momentToDate = (date) => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

// FILES
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

// DEVICE
export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return width <= 1400;
};

export const PLATFORM = {
  IOS: "iOS",
  ANDROID: "Android",
  WINDOWSPHONE: "Windows Phone",
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM.WINDOWSPHONE;
  }

  if (/android/i.test(userAgent)) {
    return PLATFORM.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORM.IOS;
  }

  return "unknown";
};

// DEBUG
export const JSONDebug = ({ data, active }) => {
  if (!active) {
    return null;
  }
  return (
    <Fragment>
      <Divider />
      <JSONPretty theme={JSONTheme} id="json-pretty" data={data || {}} />
    </Fragment>
  );
};

// FORM
const handleInitDataTypes = (data) => {
  if (isPlainObject(data) && data?.id) {
    return data?.id;
  }
  if (isPlainObject(data) && !data?.id) {
    return data;
  }
  // if (isArray(data)) {
  //   const output = reduce(
  //     data,
  //     (acc, curr) => {
  //       const current = { ...curr };
  //       for (let prop in current) {
  //         if (isPlainObject(current[prop])) {
  //           current[prop] = current[prop]?.id;
  //         }
  //       }
  //       acc.push(current);
  //       return acc;
  //     },
  //     []
  //   );
  //   return output;
  // }
  return data;
};

export const formatFormInitialData = (data) => {
  const fields = toPairs(data).reduce((flds, a) => {
    flds.push({ name: a[0], value: handleInitDataTypes(a[1]) });
    return flds;
  }, []);
  return fields;
};

// MOCK
export const mockedText = (textLenght = 1) => {
  const text = new LoremIpsum({
    sentencesPerParagraph: {
      max: 8,
      min: 4,
    },
    wordsPerSentence: {
      max: 16,
      min: 4,
    },
  });
  return text.generateSentences(textLenght);
};

// COOKIES
export const setCookie = async (key, value, exdays) => {
  await Storage.set({ key, value: JSON.stringify(value) });
};

export const getCookie = async (key) => {
  try {
    const c = await Storage.get({ key: key });
    return JSON.parse(c.value);
  } catch (error) {
    await deleteCookie(key);
  }
};

export const deleteCookie = async (key) => {
  await Storage.remove({ key });
};

export const GetActivePlugins = (activePlugins, plugins) => {
  const result = [];
  if (!plugins || !activePlugins) return null;
  Object.entries(activePlugins).forEach((plugin) => {
    if (plugins[plugin[0]] && plugin[1] === true) {
      result.push({ [plugin[0]]: plugins[plugin[0]] });
    }
  });
  return result;
};

export const calculateHours = (_id, hours) => {
  const obj = hours && hours[_id];
  if (obj && obj.endDate) {
    var duration = moment.duration(obj.endDate.diff(obj.startDate));
    return (
      duration.hours() +
      ":" +
      (duration.minutes() < 10
        ? "0" + duration.minutes()
        : duration.minutes()) +
      " horas."
    );
  }
};

const calculateCurrentHours = (_id, hours) => {
  const obj = hours && hours[_id];
  if (!obj) return 0;
  if (obj.endDate) {
    return moment.duration(obj.endDate.diff(obj.startDate));
  } else {
    return moment.duration("00:00:00");
  }
};

export const calculateTotal = (_id, hours, tasks, noInvoiced) => {
  let total = moment.duration("00:00:00");
  const obj = tasks && tasks.find((t) => t._id);
  if (obj) {
    const tasksHours =
      hours && Object.values(hours)
        .filter((h) => h.task === _id)
        .filter((h) => !noInvoiced || (noInvoiced && !h.invoiced));
    for (const hour of tasksHours) {
      total.add(calculateCurrentHours(hour._id, hours));
    }
  }
  return (
    total.hours() +
    ":" +
    (total.minutes() < 10 ? "0" + total.minutes() : total.minutes())
  );
};

export const calculateAllTotal = (data, hours, tasks, noInvoiced) => {
  let total = moment.duration("00:00:00");

  data.map((item) => {
    const obj = tasks && tasks.find((t) => t._id);
    if (obj) {
      const tasksHours =
        hours && Object.values(hours)
          .filter((h) => h.task === item._id)
          .filter((h) => !noInvoiced || (noInvoiced && !h.invoiced));;
      for (const hour of tasksHours) {
        total.add(calculateCurrentHours(hour._id, hours));
      }
    }
    return total;
  });
  return (
    (total.days() * 24) +
    total.hours() +
    ":" +
    (total.minutes() < 10 ? "0" + total.minutes() : total.minutes())
  );
};

export const regexPassword = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{5,}$/);

export const newPasswordFieldOpts = {
  validationRules: [
    {
      type: 'pattern',
      pattern: regexPassword,
    },
  ],
  helpText:
    'Longitud mínima de 5, 1 minúscula, 1 mayúscula, 1 número y un caracter especial',
};