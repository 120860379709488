import { isArray, isObject } from "lodash";
import moment from "moment";

export const formatSettings = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
      name: setting?.name,
      deleted: setting.deleted,
      startDate: setting.startDate && moment(setting.startDate),
      endDate: setting.endDate && moment(setting.endDate),
      _id: setting?._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
      name: data?.name,
      deleted: data.deleted,
      startDate: data.startDate && moment(data.startDate),
      endDate: data.endDate && moment(data.endDate),
      _id: data?._id,
    };
  }
};

export const formatResources = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
    };
  }
};

export const formatUnits = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
    };
  }
};

export const formatUpdates = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: data.name,
      value: data._id,
      date: moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
      date: moment(data.date),
    };
  }
};

export const formatNotifications = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.title}`,
      value: data._id,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
      start: setting.start && moment(setting.start),
      end: setting.end && moment(setting.end),
      id: setting._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
      id: data._id,
    };
  }
};

export const formatProducts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
    };
  }
};

export const formatWorkOrders = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
      startDate: setting.startDate && moment(setting.startDate),
      endDate: setting.endDate && moment(setting.endDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
      startDate: data.startDate && moment(data.startDate),
      endDate: data.endDate && moment(data.endDate),
    };
  }
};

export const formatTasks = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
      id: setting._id,
      text: setting.description,
      start: setting.startDate,
      end: setting.expirationDate.date,
      resource: setting.user,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
      id: data._id,
      text: data.description,
      start: data.startDate,
      end: data.expirationDate.date,
      resource: data.user,
    };
  }
};

export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((user) => ({
      label: `${user.name} ${user.surname}`,
      value: user._id,
      ...user,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`,
    };
  }
};

export const formatDepartments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      label: setting.name,
      value: setting._id,
      name: setting?.name,
      _id: setting?._id,
      tasks: setting?.task,
    }));
  }
  if (isObject(data)) {
    return {
      label: data.name,
      value: data._id,
      name: data?.name,
      _id: data?._id,
      tasks: data?.task,
    };
  }
};

export const formatProjects = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((setting) => ({
      ...setting,
      label: setting.name,
      value: setting._id,
      name: setting?.name,
      _id: setting?._id,
      tasks: setting?.task,
      icon: setting?.icon,
      observation: setting?.observation,
      user: setting?.user,
      task: setting?.task,
      startDate: setting?.startDate ? moment(setting?.startDate) : "",
      endDate: setting?.endDate ? moment(setting?.endDate) : "",
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: data.name,
      value: data._id,
      name: data?.name,
      _id: data?._id,
      tasks: data?.task,
      icon: data?.icon,
      observation: data?.observation,
      user: data?.user,
      task: data?.task,
      startDate: data?.startDate ? moment(data?.startDate) : "",
      endDate: data?.endDate ? moment(data?.endDate) : "",
    };
  }
};

export const formatClients = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((client) => ({
      label: `${client.name} - CIF:${client.vatRegisterNO}`,
      value: client._id,
      ...client,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`,
    };
  }
};

export const formatHours = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.name}`,
      value: data._id,
      ...data,
      startDate: data?.startDate ? moment(data?.startDate) : "",
      endDate: data?.endDate ? moment(data?.endDate) : "",
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      startDate: data?.startDate ? moment(data?.startDate) : "",
      endDate: data?.endDate ? moment(data?.endDate) : "",
    };
  }
};

export const formatCountries = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((countrie) => ({
      label: `${
        countrie.translations["spa"]
          ? countrie.translations["spa"]?.common
          : countrie.name
      }`,
      value: `${
        countrie.translations["spa"]
          ? countrie.translations["spa"]?.common
          : countrie.name
      }`,
    }));
  }
  if (isObject(data)) {
    return {
      label: `${data.name}`,
      value: `${data.name}`,
    };
  }
};

export const formatCities = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((countrie) => ({
      label: `${countrie.city}`,
      value: `${countrie.city}`,
    }));
  }
  if (isObject(data)) {
    return {
      label: `${data.city}`,
      value: `${data.city}`,
    };
  }
};

export const formatProvinces = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((countrie) => ({
      label: `${countrie.nm}`,
      value: `${countrie.nm}`,
    }));
  }
  if (isObject(data)) {
    return {
      label: `${data.nm}`,
      value: `${data.nm}`,
    };
  }
};
