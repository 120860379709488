import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_NOTIFICATION,
  DELETE_NOTIFICATION,
  NOTIFICATION_FORM_TOOGLE_LOADING,
  SET_NOTIFICATION_LIST,
  SHOW_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "../constants";
import { formatNotifications } from "./settingsActionsUtils";

/* NOTIFICATION LIST */
export const fetchNotifications = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.notifications.list)
      .then((response) => {
        const notifications = formatNotifications(response.data);
        dispatch({
          type: SET_NOTIFICATION_LIST,
          payload: keyBy(notifications, "_id"),
        });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// NOTIFICATIONS
export const createNotification = (newNotification) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.notifications.create, newNotification)
      .then((response) => {
        const { _id } = response.data;
        const notification = formatNotifications({ ...newNotification, _id });
        dispatch({ type: ADD_NEW_NOTIFICATION, payload: notification });
        dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Notificationa creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateNotification = (updatedNotification) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(
        `${API.notifications.edit}${updatedNotification?._id}`,
        updatedNotification
      )
      .then((response) => {
        const notification = formatNotifications(response.data);
        dispatch({ type: UPDATE_NOTIFICATION, payload: notification });
        dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Notificationa actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteNotifications = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.notifications.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_NOTIFICATION, payload: Ids });
        dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Notificationas eliminadas.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: NOTIFICATION_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
