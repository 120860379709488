import io from "socket.io-client";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://gtasks-api.app.monhotels.com"
    : "http://localhost:3005";

let socket = io(BASE_URL);

export const socketID = (_id) =>
  io(BASE_URL, {
    auth: {
      _id: _id,
    },
  });

export default socket;
