import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { SHOW_NOTIFICATION } from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import { formatAlerts } from "./settingsActionsUtils";
import {
  ADD_NEW_ALERTS,
  ALERTS_FORM_TOOGLE_LOADING,
  DELETE_ALERT,
  EDIT_SELECTED_ALERT,
  SET_ALERTS,
  UPDATE_ALERTS,
} from "../plugins/Alert/constants";
moment().locale("es");

/* ALERTS */
export const fetchAlerts = () => {
  return (dispatch) => {
    axios
      .get(API.alerts.list)
      .then((response) => {
        const alerts = formatAlerts(response.data);
        dispatch({ type: SET_ALERTS, payload: keyBy(alerts, "_id") });
      })
      .catch((err) => err);
  };
};

export const createAlerts = (newData) => {
  return async (dispatch) => {
    dispatch({ type: ALERTS_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.alerts.create, newData)
      .then((response) => {
        const alert = formatAlerts(response.data);
        dispatch({ type: ADD_NEW_ALERTS, payload: alert });
        dispatch({ type: ALERTS_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: ALERTS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateAlerts = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.alerts.edit}${updated?._id}`, updated)
      .then((response) => {
        const alert = formatAlerts(response.data);
        dispatch({ type: UPDATE_ALERTS, payload: alert });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Alerta actualizada.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const signAlerts = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.alerts.sign}${updated?._id}`, updated)
      .then((response) => {
        const alert = formatAlerts(response.data);
        dispatch({ type: UPDATE_ALERTS, payload: alert });
        dispatch({ type: EDIT_SELECTED_ALERT, payload: alert });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Alerta firmada.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteAlerts = (Id) => {
  return (dispatch) => {
    axios
      .delete(`${API.alerts.delete}${Id}`)
      .then((response) => {
        dispatch({ type: DELETE_ALERT, payload: Id });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Alerta eliminada.",
          },
        });
      })
      .catch((err) => err);
  };
};
