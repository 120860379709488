import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import {
  SET_CATEGORIES,
  SET_USER_LIST,
  ADD_NEW_PROJECT,
  SET_SERVICES,
  SET_STATUS_SETTINGS,
  SET_PROJECTS,
  SET_PRIORITIES,
  PROJECT_FORM_TOOGLE_LOADING,
  CLOSE_PROJECT_FORM,
  HIDE_ACTION_MENU,
  CATEGORIES_FORM_TOOGLE_LOADING,
  ADD_NEW_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  SHOW_NOTIFICATION,
  SET_DEPARTMENTS,
  ADD_NEW_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  SET_DEFAULT_TASK_FORM_OPTIONS,
  DEPARTMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_DEPARTMENT,
  CLOSE_DEPARTMENT_FORM,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SET_COLUMN_SETTINGS,
  UPDATE_USER,
  UPDATE_CHECKLIST,
  SET_UPDATES,
} from "../constants";
import { priority, status } from "../constants/tasks";
import moment from "moment";
import "moment/locale/es";
import { isArray, isEmpty, keyBy, xor } from "lodash";
import {
  formatDepartments,
  formatProjects,
  formatSettings,
  formatUpdates,
  formatUsers,
} from "./settingsActionsUtils";
moment().locale("es");

/* DEFAULT TASK FORM OPTIONS */
export const fetchDefaultTaskFormOptions = () => {
  return (dispatch) => {
    axios
      .get(API.tasks.defaultTaskFormOptions)
      .then((response) => {
        const defaultOptions = response.data;
        delete defaultOptions._id;
        dispatch({
          type: SET_DEFAULT_TASK_FORM_OPTIONS,
          payload: defaultOptions,
        });
      })
      .catch((err) => err);
  };
};

export const updateDefaultTaskFormOptions = (updatedOptions) => {
  return async (dispatch) => {
    const response = await axios
      .post(`${API.tasks.defaultTaskFormOptions}`, updatedOptions)
      .then((response) => {
        const defaultOptions = response.data;
        delete defaultOptions._id;
        dispatch({
          type: SET_DEFAULT_TASK_FORM_OPTIONS,
          payload: response.data,
        });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Valores por defecto actualizados.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "No se ha podido completar la operación...",
          },
        });
        return err;
      });
    return response;
  };
};

/* USER LIST */
export const fetchUsers = () => {
  return (dispatch) => {
    axios
      .get(API.users.list)
      .then((response) => {
        const users = formatUsers(response.data);
        dispatch({ type: SET_USER_LIST, payload: users });
      })
      .catch((err) => err);
  };
};

/* CATEGORIES */
export const fetchCategories = () => {
  return (dispatch) => {
    axios
      .get(API.categories.list)
      .then((response) => {
        const categories = formatSettings(response.data);
        dispatch({ type: SET_CATEGORIES, payload: keyBy(categories, "_id") });
      })
      .catch((err) => err);
  };
};

export const fetchUpdates = () => {
  return (dispatch) => {
    axios
      .get(API.update)
      .then((response) => {
        const updates = formatUpdates(response.data);
        dispatch({ type: SET_UPDATES, payload: keyBy(updates, "_id") });
      })
      .catch((err) => err);
  };
};

export const createCategory = (newCat) => {
  return async (dispatch) => {
    dispatch({ type: CATEGORIES_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.categories.create, newCat)
      .then((response) => {
        const category = formatSettings(response.data);
        dispatch({ type: ADD_NEW_CATEGORY, payload: category });
        dispatch({ type: CATEGORIES_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: CATEGORIES_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateCategory = (updatedCat) => {
  return (dispatch) => {
    axios
      .put(`${API.categories.edit}${updatedCat?._id}`, updatedCat)
      .then((response) => {
        const category = formatSettings(response.data);
        dispatch({ type: UPDATE_CATEGORY, payload: category });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Categoría actualizada.",
          },
        });
      })
      .catch((err) => err);
  };
};

export const deleteCategory = (catId) => {
  return (dispatch) => {
    axios
      .delete(`${API.categories.delete}${catId}`)
      .then((response) => {
        dispatch({ type: DELETE_CATEGORY, payload: response.data });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Categoría eliminada.",
          },
        });
      })
      .catch((err) => err);
  };
};

export const deleteChecklist = (checkId) => {
  return (dispatch) => {
    axios
      .delete(`${API.checklist.delete}${checkId}`)
      .then((response) => {
        dispatch({ type: UPDATE_CHECKLIST, payload: response.data });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Checklist eliminado.",
          },
        });
      })
      .catch((err) => err);
  };
};

/* SERVICES */
export const fetchServices = () => {
  return (dispatch) => {
    axios
      .get(API.services.list)
      .then((response) => {
        const services = formatSettings(response.data);
        dispatch({ type: SET_SERVICES, payload: keyBy(services, "_id") });
      })
      .catch((err) => err);
  };
};

export const createService = (newServ) => {
  return async (dispatch, getState) => {
    const response = await axios
      .post(API.services.create, newServ)
      .then((response) => {
        const service = formatSettings(response.data);
        dispatch({ type: ADD_NEW_SERVICE, payload: service });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message:
              (getState()?.routes["services"]?.title || "Servicio") +
              " creado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

export const updateService = (updatedServ) => {
  return async (dispatch, getState) => {
    const response = await axios
      .put(`${API.services.edit}${updatedServ?._id}`, updatedServ)
      .then((response) => {
        const service = formatSettings(response.data);
        dispatch({
          type: UPDATE_SERVICE,
          payload: formatSettings(service),
        });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message:
              (getState()?.routes["services"]?.title || "Servicio") +
              " actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

export const deleteService = (servId) => {
  return (dispatch, getState) => {
    axios
      .delete(`${API.services.delete}${servId}`)
      .then(() => {
        dispatch({ type: DELETE_SERVICE, payload: servId });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message:
              (getState()?.routes["services"]?.title || "Servicio") +
              " eliminado.",
          },
        });
      })
      .catch((err) => err);
  };
};

export const toogleUserFromService = (userId, serviceId) => {
  return async (dispatch, getState) => {
    const oldUser =
      { ...getState()?.tasksOpts?.users?.find((u) => u._id === userId) } || {};
    const updatedUser = {
      ...oldUser,
      service: xor(oldUser?.service || [], [serviceId]),
    };
    const data = { services: updatedUser?.service, user: userId };
    const response = await axios
      .put(API.services.toogleUserFromService, data)
      .then((response) => {
        dispatch({ type: UPDATE_USER, payload: updatedUser });
        return response.status;
      })
      .catch((err) => {
        console.log("🚀 ~ ERROR TOOGLING USER FROM SERVICE", err);
        return err;
      });
    return response;
  };
};

/* DEPARTMENTS */
export const fetchDepartments = () => {
  return (dispatch) => {
    axios
      .get(API.departments.list)
      .then((response) => {
        const departments = formatDepartments(response.data);
        dispatch({ type: SET_DEPARTMENTS, payload: keyBy(departments, "_id") });
      })
      .catch((err) => err);
  };
};

export const createDepartment = (data) => {
  return async (dispatch) => {
    dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.departments.create, data)
      .then((response) => {
        const department = formatDepartments(response.data);
        dispatch({ type: ADD_NEW_DEPARTMENT, payload: department });
        dispatch({ type: CLOSE_DEPARTMENT_FORM });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Departamento creado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

export const updateDepartment = (data) => {
  return async (dispatch) => {
    dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.departments.edit}${data._id}`, data)
      .then((response) => {
        const department = formatDepartments(response.data);
        dispatch({ type: UPDATE_DEPARTMENT, payload: department });
        dispatch({ type: CLOSE_DEPARTMENT_FORM });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Departamento actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: DEPARTMENT_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

export const deleteDepartment = (_id) => {
  return async (dispatch) => {
    const response = await axios
      .delete(`${API.departments.delete}${_id}`)
      .then((response) => {
        dispatch({ type: DELETE_DEPARTMENT, payload: _id });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Departamento eliminado.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "error",
            message: "Ha ocurrido un error.",
          },
        });
        return err;
      });
    return response;
  };
};

/* PROJECTS */
export const fetchProjects = () => {
  return (dispatch) => {
    axios
      .get(API.projects.list)
      .then((response) => {
        const projects = formatProjects(response.data);
        dispatch({ type: SET_PROJECTS, payload: keyBy(projects, "_id") });
      })
      .catch((err) => err);
  };
};

export const createProject = (data) => {
  return async (dispatch) => {
    dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.projects.create, data)
      .then((response) => {
        const project = formatProjects(response.data);
        dispatch({ type: ADD_NEW_PROJECT, payload: project });
        dispatch({ type: CLOSE_PROJECT_FORM });
        dispatch({ type: HIDE_ACTION_MENU });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateProject = (data) => {
  return async (dispatch) => {
    dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.projects.edit}${data?._id}`, data)
      .then((response) => {
        const project = formatProjects(response.data);
        dispatch({ type: UPDATE_PROJECT, payload: project });
        dispatch({ type: CLOSE_PROJECT_FORM });
        dispatch({ type: HIDE_ACTION_MENU });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteProject = (_id) => {
  return async (dispatch) => {
    dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.projects.edit}${_id}`)
      .then((response) => {
        dispatch({ type: DELETE_PROJECT, payload: _id });
        dispatch({ type: CLOSE_PROJECT_FORM });
        dispatch({ type: HIDE_ACTION_MENU });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

/* COLUMN SETTINGS */
export const fetchColumnSettings = () => {
  return (dispatch) => {
    axios
      .get(API.settings.columnSettings)
      .then((response) => {
        !isEmpty(response.data) &&
          isArray(response.data) &&
          dispatch({ type: SET_COLUMN_SETTINGS, payload: response.data });
      })
      .catch((err) => err);
  };
};

export const updateColumnSettings = (settings) => {
  return (dispatch) => {
    axios
      .post(API.settings.columnSettings, settings)
      .then((response) => {
        !isEmpty(response.data) &&
          dispatch({ type: SET_COLUMN_SETTINGS, payload: response.data });
      })
      .catch((err) => err);
  };
};

/* MOCKED DATA // TODO DISPATCHERS */
export const fetchMockedData = () => {
  return (dispatch) => {
    new Promise((res) => {
      setTimeout(() => {
        res({ status, priority });
      }, 500);
    }).then((response) => {
      dispatch({ type: SET_STATUS_SETTINGS, payload: response.status });
      dispatch({ type: SET_PRIORITIES, payload: response.priority });
    });
  };
};
