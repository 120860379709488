/* ALERT */
export const SET_ALERTS = "SET_ALERTS";
export const ADD_NEW_ALERTS = "ADD_NEW_ALERTS";
export const ALERTS_FORM_TOOGLE_LOADING = "ALERTS_FORM_TOOGLE_LOADING";
export const UPDATE_ALERTS = "UPDATE_ALERTS";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERTS_FORM = "OPEN_ALERTS_FORM";
export const CLOSE_ALERTS_FORM = "CLOSE_ALERTS_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
/* <---- ALERT ----> */
