import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { SHOW_NOTIFICATION } from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import { formatWorkOrders } from "./settingsActionsUtils";
import {
  ADD_NEW_WORKORDERS,
  DELETE_WORKORDER,
  WORKORDERS_FORM_TOOGLE_LOADING,
  SET_WORKORDERS,
  UPDATE_WORKORDERS,
  EDIT_SELECTED_WORKORDER,
} from "../plugins/SAT/constants";
moment().locale("es");

/* WORKORDERS */
export const fetchWorkOrders = () => {
  return (dispatch) => {
    axios
      .get(API.workOrders.list)
      .then((response) => {
        const workOrders = formatWorkOrders(response.data);
        dispatch({ type: SET_WORKORDERS, payload: keyBy(workOrders, "_id") });
      })
      .catch((err) => err);
  };
};

export const createWorkOrders = (newData) => {
  return async (dispatch) => {
    dispatch({ type: WORKORDERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.workOrders.create, newData)
      .then((response) => {
        const workOrder = formatWorkOrders(response.data);
        dispatch({ type: ADD_NEW_WORKORDERS, payload: workOrder });
        dispatch({ type: WORKORDERS_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: WORKORDERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateWorkOrders = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.workOrders.edit}${updated?._id}`, updated)
      .then((response) => {
        const workOrder = formatWorkOrders(response.data);
        dispatch({ type: UPDATE_WORKORDERS, payload: workOrder });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Hoja de trabajo actualizada.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const signWorkOrders = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.workOrders.sign}${updated?._id}`, updated)
      .then((response) => {
        const workOrder = formatWorkOrders(response.data);
        dispatch({ type: UPDATE_WORKORDERS, payload: workOrder });
        dispatch({ type: EDIT_SELECTED_WORKORDER, payload: workOrder });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Hoja de trabajo firmada.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteWorkOrders = (Id) => {
  return (dispatch) => {
    axios
      .delete(`${API.workOrders.delete}${Id}`)
      .then((response) => {
        dispatch({ type: DELETE_WORKORDER, payload: Id });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Hoja de trabajo eliminada.",
          },
        });
      })
      .catch((err) => err);
  };
};
