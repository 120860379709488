import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { SHOW_NOTIFICATION } from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import { formatUnits } from "./settingsActionsUtils";
import {
  ADD_NEW_UNITS,
  DELETE_UNIT,
  UNITS_FORM_TOOGLE_LOADING,
  SET_UNITS,
  UPDATE_UNITS,
} from "../plugins/SAT/constants";
moment().locale("es");

/* UNITS */
export const fetchUnits = () => {
  return (dispatch) => {
    axios
      .get(API.units.list)
      .then((response) => {
        const units = formatUnits(response.data);
        dispatch({ type: SET_UNITS, payload: keyBy(units, "_id") });
      })
      .catch((err) => err);
  };
};

export const createUnits = (newData) => {
  return async (dispatch) => {
    dispatch({ type: UNITS_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.units.create, newData)
      .then((response) => {
        const unit = formatUnits(response.data);
        dispatch({ type: ADD_NEW_UNITS, payload: unit });
        dispatch({ type: UNITS_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: UNITS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateUnits = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.units.edit}${updated?._id}`, updated)
      .then((response) => {
        const unit = formatUnits(response.data);
        dispatch({ type: UPDATE_UNITS, payload: unit });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Recurso actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteUnits = (Id) => {
  return (dispatch) => {
    axios
      .delete(`${API.units.delete}${Id}`)
      .then((response) => {
        dispatch({ type: DELETE_UNIT, payload: Id });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Recurso eliminado.",
          },
        });
      })
      .catch((err) => err);
  };
};
