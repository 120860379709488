import { keyBy } from "lodash";
import { axios } from "../connection/ConnectionHandler";
import {
    ADD_NEW_CHECKLIST, CHECKLIST_FORM_TOOGLE_LOADING,
    CLOSE_CHECKLIST_FORM, HIDE_ACTION_MENU, SET_ALL_CHECKLISTS,
    SHOW_NOTIFICATION, ADD_CHECKLIST_QUESTION, UPDATE_CHECKLIST_QUESTION,
    UPDATE_CHECKLIST, ANSWER_FORM_TOOGLE_LOADING,
} from "../constants";
import { API } from "../constants/api";


export const fetchAllChecklists = () => {
    return async dispatch => {
        const response = await
            axios.get(API.checklist.list).then(response => {
                const checklist = keyBy(response.data, '_id');
                dispatch({ type: SET_ALL_CHECKLISTS, payload: checklist });
                return 200;
            }).catch(err => {
                dispatch({ type: SET_ALL_CHECKLISTS, payload: {} });
                return err
            });
        return response;
    };
};

export const createChecklist = data => {
    return async dispatch => {
        dispatch({ type: CHECKLIST_FORM_TOOGLE_LOADING });
        const response = await axios.post(API.checklist.create, data)
            .then(response => {
                dispatch({ type: ADD_NEW_CHECKLIST, payload: response.data });
                dispatch({ type: CLOSE_CHECKLIST_FORM });
                dispatch({ type: HIDE_ACTION_MENU });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'info', message: 'Checklist creado con éxito.' } });
                return response.status;
            })
            .catch(err => {
                dispatch({ type: CHECKLIST_FORM_TOOGLE_LOADING });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message: 'Ha ocurrido un error al crear el checklist.' } });
                return err
            });
        return response;
    }
};

export const updateChecklist = data => {
    return async dispatch => {
        dispatch({ type: CHECKLIST_FORM_TOOGLE_LOADING });
        const response = await axios.put(`${API.checklist.edit}${data?._id}`, data)
            .then(response => {
                dispatch({ type: UPDATE_CHECKLIST, payload: response.data });
                dispatch({ type: CLOSE_CHECKLIST_FORM });
                dispatch({ type: HIDE_ACTION_MENU });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'info', message: 'Checklist actualizado.' } });
                return response.status;
            })
            .catch(err => {
                dispatch({ type: CHECKLIST_FORM_TOOGLE_LOADING });
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message: 'Ha ocurrido un error al actualizar el checklist.' } });
                return err
            });
        return response;
    }
};


/* QUESTIONS */
export const addChecklistQuestion = (checklistId, question) => {
    return async dispatch => {
        const response = await axios.post(`${API.checklist.question}${checklistId}`, question)
            .then(response => {
                dispatch({ type: ADD_CHECKLIST_QUESTION, payload: { checklistId, question: response.data } });
                return response.status;
            })
            .catch(err => err);
        return response;
    };
};

export const updateChecklistQuestion = (checklistId, question) => {
    return async dispatch => {
        const response = await axios.put(`${API.checklist.question}${question?._id}`, question)
            .then(response => {
                dispatch({ type: UPDATE_CHECKLIST_QUESTION, payload: { checklistId, question: response.data } });
                return response.status;
            })
            .catch(err => err);
        return response;
    };
};

export const deleteChecklistQuestion = (checklistId, questionId) => {
    return async dispatch => {
        const response = await axios.delete(`${API.checklist.question}${questionId}`)
            .then(response => {
                dispatch({ type: UPDATE_CHECKLIST_QUESTION, payload: { checklistId, question: response.data } })
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'warning', message: 'Pregunta eliminada.' } });
                return response.status;
            }).catch(err => {
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message: 'No se ha podido eliminar la pregunta.' } });
                return err;
            })
        return response;
    }
};


/* ANSWERS */
export const sendAnswer = (checklistId, answer) => {
    return async dispatch => {
        dispatch({ type: ANSWER_FORM_TOOGLE_LOADING });
        const response = await axios.post(`${API.checklist.answer}`, answer)
            .then(response => {
                dispatch({ type: UPDATE_CHECKLIST_QUESTION, payload: { checklistId, question: response.data } });
                dispatch({ type: ANSWER_FORM_TOOGLE_LOADING });
                // dispatch({ type: CLOSE_ANSWER_FORM });
                return response.status;
            }).catch(err => {
                dispatch({ type: SHOW_NOTIFICATION, payload: { show: true, status: 'error', message: 'Ha ocurrido un error al enviar la respuesta.' } });
                dispatch({ type: ANSWER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    }
};