import { RRule } from "rrule";
import { FinishedTaskIcon, InProcessTaskIcon, PendingTaskIcon } from "./icons";

export const TASK_FORM_MODAL_ID = "main-modal-tasks-form";

export const NORMAL_TASK_TYPE = "NORMAL";
export const QUESTION_TASK_TYPE = "QUESTION";
export const CHECKLIST_TASK_TYPE = "CHECKLIST";
export const TASK_PENDING_STATUS = "PENDING";
export const TASK_INPROCESS_STATUS = "INPROCESS";
export const TASK_FINISHED_STATUS = "FINISHED";
export const HIGH_PRIORITY_TASK = "HIGH";
export const MEDIUM_PRIORITY_TASK = "MEDIUM";
export const LOW_PRIORITY_TASK = "LOW";

export const DAILY_FREQUENCY = RRule.DAILY;
export const WEEKLY_FREQUENCY = RRule.WEEKLY;
export const MONTHLY_FREQUENCY = RRule.MONTHLY;
export const YEARLY_FREQUENCY = RRule.YEARLY;
export const DEFAULT_COUNT_FREQUENCY = 1;
export const DEFAULT_INTERVAL_FREQUENCY = 1;
export const DEFAULT_DAYS_TO_PERFORM = 0;

export const MONDAY = RRule.MO.weekday;
export const TUESDAY = RRule.TU.weekday;
export const WEDNESDAY = RRule.WE.weekday;
export const THURSDAY = RRule.TH.weekday;
export const FRIDAY = RRule.FR.weekday;
export const SATURDAY = RRule.SA.weekday;
export const SUNDAY = RRule.SU.weekday;

export const DEFAULT_WEEKSTART_DAY = MONDAY;

export const JANUARY = 1;
export const FEBRUARY = 2;
export const MARCH = 3;
export const APRIL = 4;
export const MAY = 5;
export const JUNE = 6;
export const JULY = 7;
export const AUGUST = 8;
export const SEPTEMBER = 9;
export const OCTOBER = 10;
export const NOVEMBER = 11;
export const DECEMBER = 12;

export const status = [
  {
    label: "Pendiente",
    value: TASK_PENDING_STATUS,
    color: "volcano",
    icon: <PendingTaskIcon />,
  },
  {
    label: "En proceso",
    value: TASK_INPROCESS_STATUS,
    color: "processing",
    icon: <InProcessTaskIcon />,
  },
  {
    label: "Finalizada",
    value: TASK_FINISHED_STATUS,
    color: "success",
    icon: <FinishedTaskIcon />,
  },
];

export const priority = [
  { label: "Alta", value: HIGH_PRIORITY_TASK, color: "error" },
  { label: "Media", value: MEDIUM_PRIORITY_TASK, color: "warning" },
  { label: "Baja", value: LOW_PRIORITY_TASK, color: "success" },
];

export const frequencyOpts = [
  { label: "Diariamente", value: DAILY_FREQUENCY, short: "Diaria" },
  { label: "Semanalmente", value: WEEKLY_FREQUENCY, short: "Semanal" },
  { label: "Mensualmente", value: MONTHLY_FREQUENCY, short: "Mensual" },
  { label: "Anualmente", value: YEARLY_FREQUENCY, short: "Anual" },
];

export const daysOpts = [
  { label: "L", value: MONDAY },
  { label: "M", value: TUESDAY },
  { label: "M", value: WEDNESDAY },
  { label: "J", value: THURSDAY },
  { label: "V", value: FRIDAY },
  { label: "S", value: SATURDAY },
  { label: "D", value: SUNDAY },
];

export const monthsOpts = [
  { label: "Ene", value: JANUARY },
  { label: "Feb", value: FEBRUARY },
  { label: "Mar", value: MARCH },
  { label: "Abr", value: APRIL },
  { label: "May", value: MAY },
  { label: "Jun", value: JUNE },
  { label: "Jul", value: JULY },
  { label: "Ago", value: AUGUST },
  { label: "Sep", value: SEPTEMBER },
  { label: "Oct", value: OCTOBER },
  { label: "Nov", value: NOVEMBER },
  { label: "Dic", value: DECEMBER },
];

export const ALL_TASKS = "all";

export const tasksFilters = [
  { label: "TODAS", value: ALL_TASKS },
  { label: "TODAS", value: ALL_TASKS },
  { label: "TODAS", value: ALL_TASKS },
];

export const DEFAULT_TASKS_COLUMNS = [
  { key: "pending", title: "Pendientes", filter: "pending" },
  { key: "inprocess", title: "En proceso", filter: "inprocess" },
  { key: "finalizadas", title: "Finalizadas", filter: "finished" },
  { key: "supervisadas", title: "Supervisadas", filter: "supervised" },
  { key: "mentions", title: "Menciones", filter: "mentions" },
];
