import { createSelector } from "reselect";
import moment from "moment";
import "moment/locale/es";
import { filter, find, isEmpty, map, reduce } from "lodash";
import { TASK_FINISHED_STATUS, TASK_INPROCESS_STATUS, TASK_PENDING_STATUS } from "../../constants/tasks";
import { ROLES_RAW } from "../../constants/permissions";
import { useSelector } from "react-redux";
moment().locale("es");

/* APP */
export const isUserAuthorized = createSelector(
  (state) => state.authorization,
  (authorization) => authorization
);
export const getCurrentRoute = createSelector(
  (state) => state.currentRoute,
  (currentRoute) => currentRoute
);
export const getCurrentRouteTitle = createSelector(
  getCurrentRoute,
  (route) => route.title
);
export const getSideMenuState = createSelector(
  (state) => state.app,
  (app) => app.isCollapsedSideMenu
);
export const getCurrentReportDateFilter = createSelector(
  (state) => state.app,
  (app) => app?.reportSharedData?.currentDateFilter
);

/* PROFILE */
export const getUserProfile = createSelector(
  (state) => state.profile,
  (profile) => profile
);
export const currentUserId = createSelector(
  (state) => state.profile,
  (profile) => profile && profile.id
);
export const getCurrentUserRole = createSelector(getUserProfile, (profile) =>
  ROLES_RAW.includes(profile?.role) ? profile?.role : null
);
export const getCurrentUserEstablishmentId = createSelector(
  getUserProfile,
  (profile) => profile?.establishment
);

/* TASKS */
export const getAllTasks = createSelector(
  (state) => state.tasks,
  (tasks) => tasks && Object.values(tasks)
);
export const getTotalTasks = createSelector(
  (state) => state.allTasks,
  (allTasks) => allTasks
);
export const getDeletedTasks = createSelector(
  (state) => state.deletedTasks,
  (deletedTasks) => deletedTasks
);
export const getFetchedTasks = createSelector(
  (state) => state.fetchedTasks,
  (fetchedTasks) => fetchedTasks
);
export const getTaskById = (id) =>
  createSelector(
    (state) => state.allTasks,
    (allTasks) => allTasks && allTasks[id]
  );
export const getTasksWithIds = (ids = []) =>
  createSelector(
    (state) => state.tasks,
    (tasks) => tasks && filter(tasks, (tsk) => ids.includes(tsk._id))
  );

function isEmptyString(str) {
  return !str || str.length === 0;
}

export const getTasksBy = (opts, filters) => {
  return createSelector(getAllTasks, currentUserId, (tasks, currentUser) => {
    const taskFilter = useSelector(getTaskSearchQuery);

    if (taskFilter && !isEmptyString(taskFilter.text)) {
      tasks = tasks.filter((task) =>
        task.description.toUpperCase().includes(taskFilter.text.toUpperCase())
      );
    }

    if (filters && tasks && filters.order === "down") {
      tasks = tasks.sort(function (a, b) {
        return new Date(b.startDate) - new Date(a.startDate);
      });
    } else if (filters && tasks) {
      tasks = tasks.sort(function (a, b) {
        return new Date(a.startDate) - new Date(b.startDate);
      });
    }
    if (filters && filters.duration) {
      var start = moment().format("YYYY-MM-DD");
      var end = moment().add(filters.duration, "days").format("YYYY-MM-DD");
      if (filters.duration < 0) {
        tasks = tasks?.filter(
          (x) =>
            moment(x.startDate).format("YYYY-MM-DD") < start &&
            moment(x.startDate).format("YYYY-MM-DD") >= end
        );
      } else {
        tasks = tasks?.filter(
          (x) =>
            moment(x.startDate).format("YYYY-MM-DD") >= start &&
            moment(x.startDate).format("YYYY-MM-DD") < end
        );
      }
    }

    switch (opts) {
      case "own":
        return filter(tasks, (tsk) => {
          if (
            tsk?.user === currentUser &&
            tsk?.state !== TASK_FINISHED_STATUS
          ) {
            return tsk;
          }
        });
      case "pending":
        return filter(tasks, (tsk) => {
          if (
            tsk?.user === currentUser &&
            tsk?.state === TASK_PENDING_STATUS
          ) {
            return tsk;
          }
        });
      case "inprocess":
        return filter(tasks, (tsk) => {
          if (
            tsk?.user === currentUser &&
            tsk?.state === TASK_INPROCESS_STATUS
          ) {
            return tsk;
          }
        });
      case "finished":
        return filter(tasks, (tsk) => {
          if (
            tsk?.user === currentUser &&
            tsk?.state === TASK_FINISHED_STATUS
          ) {
            return tsk;
          }
        });
      case "supervised":
        return filter(tasks, (tsk) => {
          if (
            tsk?.user !== currentUser &&
            tsk?.supervisor === currentUser &&
            tsk?.state !== TASK_FINISHED_STATUS
          ) {
            return tsk;
          }
        });
      case "mentions":
        return filter(tasks, (tsk) => {
          if (tsk?.mentions.find((x) => x === currentUser)) {
            return tsk;
          }
        });
      default:
        if (tasks) {
          delete tasks.duration;
        }
        return tasks && filter(tasks, opts);
    }
  });
};

export const getTasksByDepartment = (id) =>
  createSelector(
    getAllTasks,
    (tasks) => tasks && filter(tasks, { department: id })
  );

/* TASKS - Modal Viewer */
export const getTasksFromModalViewer = createSelector(
  (state) => state.tasksModalViewer,
  (tasks) => tasks
);

/* TASKS - Opts */
export const getTasksOpts = createSelector(
  (state) => state.tasksOpts,
  (opts) => opts
);
export const getTasksFormDefaultOptions = createSelector(
  getTasksOpts,
  (opts) => opts?.defaultOptions
);
export const getTasksOptsTypes = createSelector(
  getTasksOpts,
  (opts) => opts?.types
);
export const getTasksOptsCategories = createSelector(getTasksOpts, (opts) =>
  opts?.categories ? Object.values(opts.categories) : []
);
export const getTasksOptsProjects = createSelector(getTasksOpts, (opts) =>
  opts?.projects ? Object.values(opts.projects) : []
);
export const getTasksOptsServices = createSelector(getTasksOpts, (opts) =>
  opts?.services ? Object.values(opts.services) : []
);
export const getTasksOptsDepartments = createSelector(getTasksOpts, (opts) =>
  opts?.departments ? Object.values(opts.departments) : []
);
export const getTasksOptsStatus = createSelector(
  getTasksOpts,
  (opts) => opts?.status
);
export const getTasksOptsUsers = createSelector(getTasksOpts, (opts) =>
  opts?.users?.filter((x) => !x.deleted)
);
export const getUsers = createSelector(getTasksOpts, (opts) => opts?.users);
export const getTasksOptsPriorities = createSelector(
  getTasksOpts,
  (opts) => opts?.priorities
);

/* TASKS - Search */
export const getTaskSearchQuery = createSelector(
  (state) => state.taskSearch,
  (query) => query
);

/* TASKS - Categories */
export const getCategoryById = (id) =>
  createSelector(getTasksOptsCategories, (categories) =>
    categories?.find((cat) => cat?._id === id)
  );

/* TASKS - Services */
export const getServiceById = (id) =>
  createSelector(getTasksOptsServices, (services) =>
    services?.find((serv) => serv?._id === id)
  );

/* TASKS - Users by project */
export const getUsersByProject = (project, ids) =>
  createSelector(getAllTasks, getTasksOptsUsers, (tasks, users) => {
    const justIds = map(filter(tasks, { project }), (tsk) => tsk?.user) || [];
    if (ids) {
      return justIds;
    } else {
      return users?.filter((u) => justIds?.includes(u?._id));
    }
  });

/* CHECKLISTS */
export const getAllChecklists = createSelector(
  (state) => state.checklists,
  (checklists) => checklists && Object.values(checklists)
);

export const getCurrentUserChecklists = createSelector(
  getAllChecklists,
  currentUserId,
  (checklists, currentUser) => {
    if (!checklists || !currentUser) {
      return [];
    }

    const output = reduce(
      checklists,
      (acc, current) => {
        const questions = filter(current?.questions, {
          user: currentUser,
          deleted: false,
        });

        !isEmpty(questions) && acc.push({ ...current, questions });

        return acc;
      },
      []
    );

    return output;
  }
);

export const getSupervisedChecklists = createSelector(
  getAllChecklists,
  currentUserId,
  (checklists, currentUser) => {
    if (!checklists || !currentUser) {
      return [];
    }

    const output = reduce(
      checklists,
      (acc, current) => {
        if (
          current.supervisor === currentUser ||
          current.commandOrder?.find((x) => x === currentUser)
        ) {
          acc.push({ ...current });
        }

        return acc;
      },
      []
    );

    return output;
  }
);

/* USERS */
export const getUsersBy = (opt) =>
  createSelector(getTasksOptsUsers, (users) => users && filter(users, opt));

/* USERS - Departments */
export const getUsersByDepartmentId = (_id) =>
  createSelector(
    getTasksOptsUsers,
    (users) => users && filter(users, (u) => u?.department?.includes(_id))
  );

/* USERS - Services */
export const getUsersByServiceId = (_id) =>
  createSelector(
    getTasksOptsUsers,
    (users) => users && filter(users, (u) => u?.service?.includes(_id))
  );

export const getUserServicesByUserId = (_id) =>
  createSelector(getTasksOptsUsers, getTasksOptsServices, (users, services) => {
    if (users && services) {
      const selectedUserServices =
        find(users, (u) => u?._id === _id)?.service || [];
      const selectedUsersServicesData = [];
      for (let uSrv of selectedUserServices) {
        for (let aServ of services) {
          if (uSrv === aServ?.value) {
            selectedUsersServicesData.push(aServ);
          }
        }
      }
      return selectedUsersServicesData;
    }
  });

/* ESTABLISHMENT */
export const getEstablishment = createSelector(
  (state) => state.establishment,
  (establishment) => establishment
);
export const getEstablishmentInfo = createSelector(
  getEstablishment,
  (establishment) => establishment?.info
);
export const getEstablishmentSettings = createSelector(
  getEstablishment,
  (establishment) => establishment?.settings
);
export const getEstablishmentISO = createSelector(
  getEstablishment,
  (establishment) => establishment?.settings?.establishmentISO
);

/* TASKS FORM */
export const getTaskForm = createSelector(
  (state) => state.taskForm,
  (form) => form
);

/* CHECKLIST FORM */
export const getChecklistForm = createSelector(
  (state) => state.checklistForm,
  (form) => form
);

/* QUESTIONS FORM */
export const getQuestionsForm = createSelector(
  (state) => state.questionsForm,
  (form) => form
);

/* ANSWERS FORM */
export const getAnswersForm = createSelector(
  (state) => state.answersForm,
  (form) => form
);

/* PROJECTS FORM */
export const getProjectForm = createSelector(
  (state) => state.projectForm,
  (form) => form
);

/* DEPARTMENTS FORM */
export const getDepartmentForm = createSelector(
  (state) => state.departmentForm,
  (form) => form
);

/* CATEGORIES FORM */
export const getCategoriesForm = createSelector(
  (state) => state.categoriesForm,
  (form) => form
);

/* USERS FORM */
export const getUsersForm = createSelector(
  (state) => state.usersForm,
  (form) => form
);

/* NOTIFICATIONS STATE */
export const getNotificationsState = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

/* TOOLBAR */
export const getToolbarVisibility = createSelector(
  (state) => state.toolbar,
  (bar) => bar
);

/* ACTIONS MENU */
export const getActionsMenuState = createSelector(
  (state) => state.actionsMenu,
  (menu) => menu
);

/* COLUMN SETTINGS */
export const getColumnSettings = createSelector(
  (state) => state.columnSettings,
  (settings) => settings
);

/* HOLIDAYS */
export const getEstbalishmentHolidays = createSelector(
  (state) => state.calendar.holidays,
  (holidays) => holidays.establishment
);

/* CHAT */
export const getChatCurrentUser = createSelector(
  (state) => state.chats,
  (chats) => chats
);
export const getMessagesChat = createSelector(
  (state) => state.messages,
  (messages) => messages
);
export const getCurrentChat = createSelector(
  (state) => state.currentChat,
  (currentChat) => currentChat
);

/* ROUTES */
export const getRoutesKey = createSelector(
  (state) => state.routes,
  (routes) => routes
);
export const getRoutes = createSelector(
  (state) => state.routes,
  (routes) =>
    routes ? Object.values(routes).sort((a, b) => a.order - b.order) : routes
);

/* CLIENTS */
export const getClients = createSelector(
  (state) => state.clients,
  (clients) => clients
);
/* CLIENTS FORM */
export const getClientsForm = createSelector(
  (state) => state.clientsForm,
  (form) => form
);

/* COUNTRIES */
export const getCountries = createSelector(
  (state) => state.countries,
  (countries) => countries
);

/* CITIES */
export const getCities = createSelector(
  (state) => state.cities,
  (cities) => cities
);

/* PROVINCES */
export const getProvinces = createSelector(
  (state) => state.provinces,
  (provinces) => provinces
);

/* PROVIDERS */
export const getProviders = createSelector(
  (state) => state.providers,
  (providers) => providers
);
/* PROVIDERS FORM */
export const getProvidersForm = createSelector(
  (state) => state.providersForm,
  (form) => form
);

/* RESOURCES */
export const getResources = createSelector(
  (state) => state.resources,
  (resources) => resources
);
/* RESOURCES FORM */
export const getResourcesForm = createSelector(
  (state) => state.resourcesForm,
  (form) => form
);

/* UNITS */
export const getUnits = createSelector(
  (state) => state.units,
  (units) => units
);
/* UNITS FORM */
export const getUnitsForm = createSelector(
  (state) => state.unitsForm,
  (form) => form
);

/* PRODUCTS */
export const getProducts = createSelector(
  (state) => state.products,
  (products) => products
);
/* PRODUCTS FORM */
export const getProductsForm = createSelector(
  (state) => state.productsForm,
  (form) => form
);

/* WORKORDERS */
export const getWorkOrders = createSelector(
  (state) => state.workOrders,
  (workOrders) => workOrders
);
/* WORKORDERS FORM */
export const getWorkOrdersForm = createSelector(
  (state) => state.workOrdersForm,
  (form) => form
);

/* ALERTS */
export const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);
/* ALERTS FORM */
export const getAlertsForm = createSelector(
  (state) => state.alertsForm,
  (form) => form
);

/* WORKORDERS */
export const getHours = createSelector(
  (state) => state.hours,
  (hours) => hours
);
/* WORKORDERS FORM */
export const getHoursForm = createSelector(
  (state) => state.hoursForm,
  (form) => form
);
export const getStadistics = createSelector(
  (state) => state.stadistics,
  (stadistics) => stadistics
);
/* NOTIFICATION */
export const getNotifications = createSelector(
  (state) => state.notificationsPush,
  (notificationsPush) => notificationsPush
);

/* UPDATES */
export const getUpdates = createSelector(
  (state) => state.updates,
  (updates) => updates
);

export const getFinishedState = createSelector(
  (state) => state.finishedTasks,
  (finishedTasks) => finishedTasks
);
