import moment from "moment";
import "moment/locale/es";
moment().locale("es");

export const CHECKLIST_TASK_FORM_MODAL_ID = "main-modal-checklist-tasks-form";

export const YESNO_QUEST_TYPE = "yesNo";
export const NUMBER_QUEST_TYPE = "number";
export const TEXT_QUEST_TYPE = "text";

export const POSITIVE_ANSWER = "SI";
export const NEGATIVE_ANSWER = "NO";

export const QuestTypeOpts = [
  { label: "SI / NO", value: YESNO_QUEST_TYPE },
  { label: "Número", value: NUMBER_QUEST_TYPE },
  { label: "Texto", value: TEXT_QUEST_TYPE },
];

export const yesNoOpts = [
  { label: "SI", value: POSITIVE_ANSWER },
  { label: "NO", value: NEGATIVE_ANSWER },
];

export const defaultDateFilter = { date: moment(), interval: "week" };

export const defaultSort = { key: "question", order: "asc" };
