import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { SHOW_NOTIFICATION } from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import { formatResources } from "./settingsActionsUtils";
import {
  ADD_NEW_RESOURCES,
  DELETE_RESOURCE,
  RESOURCES_FORM_TOOGLE_LOADING,
  SET_RESOURCES,
  UPDATE_RESOURCES,
} from "../plugins/SAT/constants";
moment().locale("es");

/* RESOURCES */
export const fetchResources = () => {
  return (dispatch) => {
    axios
      .get(API.resources.list)
      .then((response) => {
        const resources = formatResources(response.data);
        dispatch({ type: SET_RESOURCES, payload: keyBy(resources, "_id") });
      })
      .catch((err) => err);
  };
};

export const createResources = (newData) => {
  return async (dispatch) => {
    dispatch({ type: RESOURCES_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.resources.create, newData)
      .then((response) => {
        const resource = formatResources(response.data);
        dispatch({ type: ADD_NEW_RESOURCES, payload: resource });
        dispatch({ type: RESOURCES_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: RESOURCES_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateResources = (updated) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.resources.edit}${updated?._id}`, updated)
      .then((response) => {
        const resource = formatResources(response.data);
        dispatch({ type: UPDATE_RESOURCES, payload: resource });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Recurso actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteResources = (Id) => {
  return (dispatch) => {
    axios
      .delete(`${API.resources.delete}${Id}`)
      .then((response) => {
        dispatch({ type: DELETE_RESOURCE, payload: Id });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Recurso eliminado.",
          },
        });
      })
      .catch((err) => err);
  };
};
