/* CLIENT */
export const SET_CLIENTS = "SET_CLIENTS";
export const ADD_NEW_CLIENTS = "ADD_NEW_CLIENTS";
export const CLIENTS_FORM_TOOGLE_LOADING = "CLIENTS_FORM_TOOGLE_LOADING";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const OPEN_CLIENTS_FORM = "OPEN_CLIENTS_FORM";
export const CLOSE_CLIENTS_FORM = "CLOSE_CLIENTS_FORM";
export const EDIT_SELECTED_CLIENT = "EDIT_SELECTED_CLIENT";
/* <---- CLIENT ----> */

/* RESOURCE */
export const SET_RESOURCES = "SET_RESOURCES";
export const ADD_NEW_RESOURCES = "ADD_NEW_RESOURCES";
export const RESOURCES_FORM_TOOGLE_LOADING = "RESOURCES_FORM_TOOGLE_LOADING";
export const UPDATE_RESOURCES = "UPDATE_RESOURCES";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const OPEN_RESOURCES_FORM = "OPEN_RESOURCES_FORM";
export const CLOSE_RESOURCES_FORM = "CLOSE_RESOURCES_FORM";
export const EDIT_SELECTED_RESOURCE = "EDIT_SELECTED_RESOURCE";
/* <---- RESOURCE ----> */

/* UNIT */
export const SET_UNITS = "SET_UNITS";
export const ADD_NEW_UNITS = "ADD_NEW_UNITS";
export const UNITS_FORM_TOOGLE_LOADING = "UNITS_FORM_TOOGLE_LOADING";
export const UPDATE_UNITS = "UPDATE_UNITS";
export const DELETE_UNIT = "DELETE_UNIT";
export const OPEN_UNITS_FORM = "OPEN_UNITS_FORM";
export const CLOSE_UNITS_FORM = "CLOSE_UNITS_FORM";
export const EDIT_SELECTED_UNIT = "EDIT_SELECTED_UNIT";
/* <---- UNIT ----> */

/* PRODUCT */
export const SET_PRODUCTS = "SET_PRODUCTS";
export const ADD_NEW_PRODUCTS = "ADD_NEW_PRODUCTS";
export const PRODUCTS_FORM_TOOGLE_LOADING = "PRODUCTS_FORM_TOOGLE_LOADING";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const OPEN_PRODUCTS_FORM = "OPEN_PRODUCTS_FORM";
export const CLOSE_PRODUCTS_FORM = "CLOSE_PRODUCTS_FORM";
export const EDIT_SELECTED_PRODUCT = "EDIT_SELECTED_PRODUCT";
/* <---- PRODUCT ----> */

/* WORKORDER */
export const SET_WORKORDERS = "SET_WORKORDERS";
export const ADD_NEW_WORKORDERS = "ADD_NEW_WORKORDERS";
export const WORKORDERS_FORM_TOOGLE_LOADING = "WORKORDERS_FORM_TOOGLE_LOADING";
export const UPDATE_WORKORDERS = "UPDATE_WORKORDERS";
export const DELETE_WORKORDER = "DELETE_WORKORDER";
export const OPEN_WORKORDERS_FORM = "OPEN_WORKORDERS_FORM";
export const CLOSE_WORKORDERS_FORM = "CLOSE_WORKORDERS_FORM";
export const EDIT_SELECTED_WORKORDER = "EDIT_SELECTED_WORKORDER";
/* <---- WORKORDER ----> */

/* PROVIDER */
export const SET_PROVIDERS = "SET_PROVIDERS";
export const ADD_NEW_PROVIDERS = "ADD_NEW_PROVIDERS";
export const PROVIDERS_FORM_TOOGLE_LOADING = "PROVIDERS_FORM_TOOGLE_LOADING";
export const UPDATE_PROVIDERS = "UPDATE_PROVIDERS";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const OPEN_PROVIDERS_FORM = "OPEN_PROVIDERS_FORM";
export const CLOSE_PROVIDERS_FORM = "CLOSE_PROVIDERS_FORM";
export const EDIT_SELECTED_PROVIDER = "EDIT_SELECTED_PROVIDER";
/* <---- PROVIDER ----> */
