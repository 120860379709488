import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import {
  CATEGORIES_FORM_TOOGLE_LOADING,
  SHOW_NOTIFICATION,
  SET_COUNTRIES,
  SET_CITIES,
  SET_PROVINCES,
} from "../constants";
import moment from "moment";
import "moment/locale/es";
import { keyBy } from "lodash";
import {
  formatCities,
  formatClients,
  formatCountries,
  formatProvinces,
} from "./settingsActionsUtils";
import Axios from "axios";
import {
  ADD_NEW_CLIENTS,
  ADD_NEW_PROVIDERS,
  CLIENTS_FORM_TOOGLE_LOADING,
  DELETE_CLIENT,
  DELETE_PROVIDER,
  PROVIDERS_FORM_TOOGLE_LOADING,
  SET_CLIENTS,
  SET_PROVIDERS,
  UPDATE_CLIENTS,
  UPDATE_PROVIDERS,
} from "../plugins/SAT/constants";
moment().locale("es");

/* CLIENTS */
export const fetchClients = () => {
  return (dispatch) => {
    axios
      .get(API.clients.list)
      .then((response) => {
        const clients = formatClients(response.data);
        dispatch({ type: SET_CLIENTS, payload: keyBy(clients, "_id") });
      })
      .catch((err) => err);
  };
};

export const createClients = (newCat) => {
  return async (dispatch) => {
    dispatch({ type: CATEGORIES_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.clients.create, newCat)
      .then((response) => {
        const client = formatClients(response.data);
        dispatch({ type: ADD_NEW_CLIENTS, payload: client });
        dispatch({ type: CLIENTS_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: CLIENTS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateClients = (updatedCat) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.clients.edit}${updatedCat?._id}`, updatedCat)
      .then((response) => {
        const client = formatClients(response.data);
        dispatch({ type: UPDATE_CLIENTS, payload: client });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Cliente actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteClients = (catId) => {
  return (dispatch) => {
    axios
      .delete(`${API.clients.delete}${catId}`)
      .then((response) => {
        const client = formatClients(response.data);
        dispatch({ type: DELETE_CLIENT, payload: client });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Cliente eliminada.",
          },
        });
      })
      .catch((err) => err);
  };
};

/* PROVIDERS */
export const fetchProviders = () => {
  return (dispatch) => {
    axios
      .get(API.providers.list)
      .then((response) => {
        const providers = formatClients(response.data);
        dispatch({ type: SET_PROVIDERS, payload: keyBy(providers, "_id") });
      })
      .catch((err) => err);
  };
};

export const createProviders = (newCat) => {
  return async (dispatch) => {
    dispatch({ type: CATEGORIES_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.providers.create, newCat)
      .then((response) => {
        const provider = formatClients(response.data);
        dispatch({ type: ADD_NEW_PROVIDERS, payload: provider });
        dispatch({ type: PROVIDERS_FORM_TOOGLE_LOADING });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: PROVIDERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateProviders = (updatedCat) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.providers.edit}${updatedCat?._id}`, updatedCat)
      .then((response) => {
        const provider = formatClients(response.data);
        dispatch({ type: UPDATE_PROVIDERS, payload: provider });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Proveedor actualizado.",
          },
        });
        return response.status;
      })
      .catch((err) => console.log(err));
    return response;
  };
};

export const deleteProviders = (catId) => {
  return (dispatch) => {
    axios
      .delete(`${API.providers.delete}${catId}`)
      .then((response) => {
        const provider = formatClients(response.data);
        dispatch({ type: DELETE_PROVIDER, payload: provider });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "warning",
            message: "Proveedor eliminado.",
          },
        });
      })
      .catch((err) => err);
  };
};

/* COUNTRIES */
export const fetchCountries = () => {
  return (dispatch) => {
    Axios.get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countries = formatCountries(response.data);
        dispatch({ type: SET_COUNTRIES, payload: countries });
      })
      .catch((err) => err);
  };
};

/* CITIES */
export const fetchCities = () => {
  return (dispatch) => {
    Axios.get("https://countriesnow.space/api/v0.1/countries/population/cities")
      .then((response) => {
        const cities = formatCities(response.data.data);
        dispatch({ type: SET_CITIES, payload: cities });
      })
      .catch((err) => err);
  };
};

/* CITIES */
export const fetchProvinces = () => {
  return (dispatch) => {
    Axios.get(
      "https://raw.githubusercontent.com/IagoLast/pselect/master/data/provincias.json"
    )
      .then((response) => {
        const provinces = formatProvinces(response.data);
        dispatch({ type: SET_PROVINCES, payload: provinces });
      })
      .catch((err) => err);
  };
};
