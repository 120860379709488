import { keyBy } from "lodash";
import { API } from "../constants/api";
import {
  CREATE_ROUTE,
  DELETE_ROUTE,
  SET_ALL_ROUTES,
  SHOW_NOTIFICATION,
  UPDATE_ROUTE,
} from "../constants";
import { axios } from "../connection/ConnectionHandler";

export const fetchAllRoutes = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.route.list)
      .then((response) => {
        const routes = keyBy(response.data, "keyapp");
        dispatch({ type: SET_ALL_ROUTES, payload: routes });
        return 200;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SET_ALL_ROUTES, payload: {} });
        return err;
      });
    return response;
  };
};

export const updateRoute = (data) => {
  return async (dispatch) => {
    const response = await axios
      .put(`${API.route.list}${data._id}`, data)
      .then((response) => {
        dispatch({ type: UPDATE_ROUTE, payload: response.data });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: "info", message: "Ruta modificada." },
        });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return response;
  };
};

export const createRoute = (data) => {
  return async (dispatch) => {
    const response = await axios
      .post(`${API.route.create}`, data)
      .then((response) => {
        dispatch({ type: CREATE_ROUTE, payload: response.data });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: "info", message: "Ruta creada." },
        });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return response;
  };
};

export const updateManyRoutes = (routes2) => {
  return async (dispatch) => {
    const response = await axios
      .put(API.route.list, routes2)
      .then((response) => {
        const routes = keyBy(response.data, "keyapp");
        dispatch({ type: SET_ALL_ROUTES, payload: routes });
        return 200;
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SET_ALL_ROUTES, payload: {} });
        return err;
      });
    return response;
  };
};

export const deleteRoute = (route) => {
  return async (dispatch) => {
    const response = await axios
      .delete(`${API.route.delete}${route._id}`)
      .then((response) => {
        dispatch({ type: DELETE_ROUTE, payload: route.keyapp });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: "info", message: "Ruta eliminada." },
        });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return response;
  };
};

export const deleteAllRoute = () => {
  return async (dispatch) => {
    const response = await axios
      .delete(`${API.route.delete}all`)
      .then((response) => {
        dispatch({ type: SET_ALL_ROUTES, payload: null });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: { show: true, status: "info", message: "Rutas eliminadas." },
        });
        return response.status;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return response;
  };
};
